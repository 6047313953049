<template>
  <a-modal
    v-model="visible"
    width="1100px"
    title="工人合同列表"
    :footer="false"
    class="add-invoice"
    @close="handleCancel"
  >
    <div class="add-invoice-content" style="padding-bottom: 50px">
      <p-table ref="table" row-key="id" extraHeight="500" :sourceData="getDataApi" :columns="columns">
        <template slot="actions" slot-scope="text, record">
          <a-popconfirm
            title="确认作废吗？"
            v-if="record.signStatus == 0"
            ok-text="是"
            cancel-text="否"
            @confirm="handleInvalidation(record)"
          >
            <span class="operation_btn">作废</span>
          </a-popconfirm>
        </template>
      </p-table>
    </div>
  </a-modal>
</template>

<script>
import moment from 'moment'
import { userContractList } from '@/api/workbench'
import { invalidation } from '@/api/recruitUse'
export default {
  data () {
    return {
      visible: false,
      rowItem: {},
      signStatus: {
        0: '待用户签署',
        20: '待平台响应',
        30: '签署完成',
        40: '已作废'
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: 60,
          customRender: (t, r, i) => i + 1
        },
        {
          title: '合同编号',
          width: 300,
          dataIndex: 'contractNumber',
          key: 'contractNumber'
        },
        {
          title: '合同开始时间',
          dataIndex: 'startTime',
          key: 'startTime',
          customRender: (t) => t && moment(t).format('YYYY-MM-DD')
        },
        {
          title: '合同结束时间',
          dataIndex: 'endTime',
          key: 'endTime',
          customRender: (t) => t && moment(t).format('YYYY-MM-DD')
        },
        {
          title: '签订时间',
          dataIndex: 'asignDate',
          key: 'asignDate',
          customRender: (t) => t && moment(t).format('YYYY-MM-DD')
        },
        {
          title: '合同状态',
          dataIndex: 'signStatus',
          key: 'signStatus',
          customRender: (t) => {
            if (t) {
              return this.signStatus[t]
            }
            return ''
          }
        },
        {
          title: '操作',
          dataIndex: 'actions',
          width: 80,
          key: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    show (rowItem) {
      this.rowItem = { ...rowItem }
      this.visible = true
      this.$nextTick(() => {
        this.$refs.table.refresh()
      })
    },
    getDataApi (params) {
      const { userId, demandId } = this.rowItem
      if (userId && demandId) {
        return userContractList({
          demandId,
          BId: userId,
          ...params
          // columnKey: 'createTime',
          // order: 'asc'
          // status: 1,
        })
      } else {
        return Promise.resolve()
      }
    },
    handleCancel () {
      this.confirmLoading = false
      this.visible = false
      this.rowItem = {}
    },
    handleInvalidation (rowItem) {
      invalidation(rowItem.id).then((res) => {
        if (res.success) {
          this.$message.success('操作成功')
        } else {
          this.$message.success('操作失败')
        }
        this.$refs.table.refresh()
      })
    }
  }
}
</script>
